import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import BakeryCream from "../../components/en/BakeryCream"
import Footer from "../../components/en/Footer"

class BakeryCreamPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Cream</title>
          <meta
            name="description"
            content="Cream namenjen je za spravljanje najfinih čudesa za koje su zaduženi naši mađioničari koji prave slastice za najlepša sjećanja. Pokušajte."
          />
          <meta
            name="image"
            content="https://waltz.rs/waltz_cream_velika_seo.jpg"
          />
          <meta itemprop="name" content="WALTZ | Cream" />
          <meta
            itemprop="description"
            content="Cream namenjen je za spravljanje najfinih čudesa za koje su zaduženi naši mađioničari koji prave slastice za najlepša sjećanja. Pokušajte."
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_cream_velika_seo.jpg"
          />
          <meta name="og:title" content="WALTZ | Cream" />
          <meta
            name="og:description"
            content="Cream namenjen je za spravljanje najfinih čudesa za koje su zaduženi naši mađioničari koji prave slastice za najlepša sjećanja. Pokušajte."
          />
          <meta
            name="og:image"
            content="https://waltz.rs/waltz_cream_velika_seo.jpg"
          />
          <meta name="og:url" content="https://waltz.rs/cream" />
          <meta name="og:site_name" content="WALTZ | Cream" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <BakeryCream />
        <Footer />
      </Layout>
    )
  }
}
export default BakeryCreamPage
